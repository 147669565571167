import React, { useRef } from 'react';
import { Popover, Tabs } from 'antd';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';

// components
import Layout from '../components/layout';
import Seo from '../components/seo';
import MiniHeader from '../components/mini-header';

// img
import headerBackground from '../images/new-construction/header-newConstruction.jpg';

// styled-component
const NewConstructionIntroH1 = styled.h1`
  font-weight: 100;
  padding: 1rem 0;
`;

const NewConstructionIntroSpan = styled.span`
  font-size: 1.15rem;
  color: #666666;
  font-weight: 600;
`;

const DContainer = styled.div`
  max-width: 90%;
  width: 980px;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    max-width: 85%;
  }
`;

const NewConstructionIntroP = styled.p`
  margin: 1.3rem 0;
  font-size: 1rem;
  font-weight: 100;
  color: #666666;
`;

const NewConstructionTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  align-items: center;
  padding: 15px 0;
`;

const NewConstructionTitleLarge = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 15px 0;
`;

const NewConstructionTitleText = styled.div`
  text-align: center;
  text-transform: uppercase;
  color: #999999;
  font-size: 18px;
  line-height: 1;
`;

const NewConstructionTitleSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #999999;
`;

const NewConstructionOffer = styled.div`
  color: #999999;
  padding: 15px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const NewConstructionOfferTitle = styled.span`
  font-size: 1.2rem;
  font-weight: 100;
  cursor: pointer;
  margin: 5px 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const NewConstructionWallSystems = styled.div`
  padding: 1rem 0;
`;

const WallSystemsIntro = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 24px;
  font-size: 16px;

  @media only screen and (max-width: 568px) {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
`;

const WallSystemsIntroH1 = styled.h1`
  font-weight: 100;

  @media only screen and (max-width: 568px) {
    margin-top: 25px;
  }
`;
const WallSystemsIntroP = styled.p`
  color: #666666;

  &:last-child {
    font-weight: 100;
  }
`;

const WallSystemsTabs = styled.div`
  padding: 2rem 0;
`;
const WallSystemsTab = styled.div`
  padding: 1rem 0;
  font-size: 16px;
  font-weight: 100;
  color: #666666;
`;

const WallSystemsTabH1 = styled.h1`
  font-weight: 100;
`;

const WallSystemsTabNorma = styled.p`
  text-align: right;
  font-size: 13.3px;
`;

const WallSystemsFeatured = styled.div`
  text-align: center;
  padding: 1rem 0 1.5rem 0;
  width: 300px;
  margin: 0 auto;
`;

const WallSystemsFeaturedTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

const WallSystemsFeaturedTitleSpan = styled.span`
  font-size: 1rem;
  color: #999999;
  margin-bottom: 1.5rem;
`;

const WallSystemsFeaturedTitleP = styled.p`
  font-size: 1.15rem;
  margin-bottom: 8px;
  border-bottom: 1px solid rgb(255, 215, 0);
`;

const WallSystemsFeaturedTitleImage = styled.div`
  width: 300px;
  margin: 0 auto;

  @media only screen and (max-width: 568px) {
    width: 275px;
    margin: 3px auto;
  }
`;

const AirBarries = styled.div`
  background-color: #f0f0f0;
  padding: 2rem 0;
`;

const AirBarriesIntro = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 24px;
  font-size: 16px;

  @media only screen and (max-width: 568px) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 15px;
  }
`;

const AirBarriesIntroH1 = styled.h1`
  font-weight: 100;

  @media only screen and (max-width: 568px) {
    margin-top: 25px;
  }
`;

const AirBarriesIntroP = styled.p`
  color: #666666;

  &:last-child {
    font-weight: 100;
  }
`;

const Coatings = styled.div`
  padding: 2rem 0;
`;

const CoatingsIntro = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 24px;
  font-size: 16px;

  @media only screen and (max-width: 568px) {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
`;

const CoatingsIntroH1 = styled.h1`
  font-weight: 100;

  @media only screen and (max-width: 568px) {
    margin-top: 25px;
  }
`;

const CoatingsIntroP = styled.p`
  color: #666666;

  &:last-child {
    font-weight: 100;
  }
`;

const { TabPane } = Tabs;

const popTitleWall = <span style={{ fontSize: '16px', color: '#000' }}>Sistema para muros</span>;

const popTitleAir = (
  <span style={{ fontSize: '16px', color: '#000' }}>Barrera de Aire & Humedad</span>
);

const popTitleCoatings = <span style={{ fontSize: '16px', color: '#000' }}>Pinturas</span>;

const popTitleSystem = <span style={{ fontSize: '16px', color: '#000' }}>Sistemas Especiales</span>;

const popTitleFinishes = <span style={{ fontSize: '16px', color: '#000' }}>Acabados</span>;

const popOverWall = (
  <p style={{ width: '300px' }}>
    Los sistemas de muros de alto rendimiento de Sto incluyen la barrera de aire y humedad, el
    drenaje y los elementos de aislamiento continuo que sus muros necesitan para cumplir con el
    código, ser eficientes en el uso de la energía y ser duraderos.
  </p>
);

const popOverAir = (
  <p style={{ width: '300px' }}>
    Las Barreras de Aire y Humedad funcionan construyendo una envoltura hermética continua del
    edificio. Son necesarias para los muros de los edificios, independientemente de la región o el
    clima.
  </p>
);

const popOverCoatings = (
  <p style={{ width: '300px' }}>
    Los recubrimientos arquitectónicos exteriores de Sto son capaces de repeler la lluvia impulsada
    por el viento, pero aún así permiten que un edificio respire.
  </p>
);

const popOverSystem = (
  <p style={{ width: '300px' }}>
    Nuestro portafolio de sistemas incluye una serie de ensamblajes de pared especiales diseñados
    para cumplir con los requisitos de diseño muy particulares del recinto del edificio.
  </p>
);

const popOverFinishes = (
  <p style={{ width: '300px' }}>
    Para la personalización que desea, junto con el ahorro de costes y el rendimiento que necesita,
    elija Acabados Especiales de Sto.
  </p>
);

const scrollToRef = (ref) => ref.current && window.scrollTo(0, ref.current.offsetTop - 100);

const NewConstruction = React.memo(() => {
  const wallRef = useRef(null);
  const airRef = useRef(null);
  const coatingsRef = useRef(null);
  const systemsRef = useRef(null);
  const finishesRef = useRef(null);
  const executeScroll = (ref) => scrollToRef(ref);
  const data = useStaticQuery(graphql`
    {
      newWalls: file(relativePath: { eq: "new-construction/1_1_0_2_NewBuild_Walls_2_web.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 450, quality: 100, layout: CONSTRAINED)
        }
      }
      airBarries: file(relativePath: { eq: "new-construction/1_1_0_1_NewBuild_AM_2_web.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 450, quality: 100, layout: CONSTRAINED)
        }
      }
      coatings: file(relativePath: { eq: "new-construction/1_1_0_5_NewBuild_Coating_2_web.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 450, quality: 100, layout: CONSTRAINED)
        }
      }
      finishes: file(relativePath: { eq: "new-construction/1_1_0_4_NewBuild_Finish_2_web.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 450, quality: 100, layout: CONSTRAINED)
        }
      }
      stoWallSystem: file(relativePath: { eq: "new-construction/COMPARE_StoTherm_ci.png" }) {
        childImageSharp {
          gatsbyImageData(width: 250, quality: 100, layout: CONSTRAINED)
        }
      }
      specialSystem: file(
        relativePath: { eq: "new-construction/1_2_3_1_NewBuild_Specialty_2_web.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 250, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo title="Nueva Construccion" />
      <MiniHeader text="Nueva Construcción" background={headerBackground} />
      <article>
        <DContainer>
          <div>
            <NewConstructionIntroH1>Sistemas & Productos Sto</NewConstructionIntroH1>
            <strong>
              <NewConstructionIntroSpan>
                Sto entiende sus necesidades ofreciendo productos y soluciones
                integrales,inteligentes e innovadoras
              </NewConstructionIntroSpan>
            </strong>
            <NewConstructionIntroP>
              Al ser fabricantes de productos de construcción, Sto ayuda a crear un cambio en la
              metodologia de construcción tradicional. Nuestras soluciones son de alta
              calidad,protegiendo y mejorando la visión tanto del diseño,como la estructura y la
              imagen de los proyectos, ayudandole a construir de una forma versátil y eficiente
              desde el exterior hacia el interior
            </NewConstructionIntroP>
          </div>
          <NewConstructionTitle>
            <NewConstructionTitleSeparator />
            <NewConstructionTitleText>Nuestra Oferta</NewConstructionTitleText>
            <NewConstructionTitleSeparator />
          </NewConstructionTitle>
          <section ref={wallRef}>
            <Fade bottom>
              <NewConstructionOffer>
                <Popover content={popOverWall} title={popTitleWall}>
                  <NewConstructionOfferTitle
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => executeScroll(wallRef)}
                    onClick={() => executeScroll(wallRef)}
                  >
                    Sistemas para Muros
                  </NewConstructionOfferTitle>
                </Popover>
                <Popover content={popOverAir} title={popTitleAir}>
                  <NewConstructionOfferTitle
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => executeScroll(airRef)}
                    onClick={() => executeScroll(airRef)}
                  >
                    Barrera de Aire & Humedad
                  </NewConstructionOfferTitle>
                </Popover>
                <Popover content={popOverCoatings} title={popTitleCoatings}>
                  <NewConstructionOfferTitle
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => executeScroll(coatingsRef)}
                    onClick={() => executeScroll(coatingsRef)}
                  >
                    Pinturas
                  </NewConstructionOfferTitle>
                </Popover>
                <Popover content={popOverSystem} title={popTitleSystem}>
                  <NewConstructionOfferTitle
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => executeScroll(systemsRef)}
                    onClick={() => executeScroll(systemsRef)}
                  >
                    Sistemas Especiales
                  </NewConstructionOfferTitle>
                </Popover>
                <Popover content={popOverFinishes} title={popTitleFinishes}>
                  <NewConstructionOfferTitle
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => executeScroll(finishesRef)}
                    onClick={() => executeScroll(finishesRef)}
                  >
                    Acabados
                  </NewConstructionOfferTitle>
                </Popover>
              </NewConstructionOffer>
            </Fade>
          </section>
          <NewConstructionWallSystems>
            <Fade right>
              <WallSystemsIntro>
                <div>
                  <GatsbyImage
                    image={data.newWalls.childImageSharp.gatsbyImageData}
                    alt="logo-newWalls"
                  />
                </div>
                <div>
                  <WallSystemsIntroH1>Sistema para Muros</WallSystemsIntroH1>
                  <WallSystemsIntroP>
                    <strong>
                      <span>
                        Sto es una marca lider en el mercado de sistemas de aislamiento térmico para
                        muros exteriores
                      </span>
                    </strong>
                  </WallSystemsIntroP>
                  <WallSystemsIntroP>
                    Los sistemas de paredes de alto rendimiento de Sto incluyen la barrera de aire y
                    humedad, el drenaje y los elementos de aislamiento continuo que sus paredes
                    necesitan para cumplir con el código, la eficiencia energética y la durabilidad.
                  </WallSystemsIntroP>
                </div>
              </WallSystemsIntro>
            </Fade>
            <WallSystemsTabs>
              <Tabs defaultActiveKey="1" type="card" size="small">
                <TabPane tab="Rendimiento Térmico" key="1">
                  <WallSystemsTab>
                    <WallSystemsTabH1>Rendimiento Térmico</WallSystemsTabH1>
                    <p>
                      La pérdida de calor puede afectar significativamente al costo de operacón un
                      edificio, así como a la comodidad de los que están dentro. Sto tiene más de 30
                      años de experiencia y aplicación práctica de sistemas de aislamiento térmico
                      para muros exteriores, lo que significa que puede confiar en nosotros para
                      proporcionar la solución adecuada para sus necesidades. Aislar térmicamente el
                      exterior de la estructura del muro con aislamiento continuo tiene un valor
                      técnico en varios niveles.
                    </p>
                    <ul>
                      <li>El edificio está recubierto en una manta protectora y aislada.</li>
                      <li>
                        Los efectos de los puentes térmicos se anulan y los detalles para lograr un
                        alto rendimiento térmico se simplifican en gran medida.
                      </li>
                      <li>
                        Cuando se combina con acabados de alto rendimiento, la capa de aislamiento
                        mantiene la estructura de la pared caliente y seca. Esto mejora aún más su
                        rendimiento térmico.
                      </li>
                      <li>
                        El uso de un espesor suficiente de aislamiento continuo para muros
                        exteriores mantiene la temperatura de la estructura del muro del edificio
                        por encima del "punto de rocío", tratando eficazmente el potencial de
                        condensación causado por la difusión del vapor de agua y el riesgo asociado
                        de crecimiento de moho.
                      </li>
                      <li>
                        El cumplimiento de las últimas normas y códigos (ASHRAE 90.1-2013 y IBC, IRC
                        e IECC - 2015)
                      </li>
                    </ul>
                    <WallSystemsTabNorma>
                      *2012 Código Internacional de Conservación de la Energía (IECC), que
                      esencialmente adopta los requisitos de la ci Norma ASHRAE 90.1-2010
                    </WallSystemsTabNorma>
                  </WallSystemsTab>
                </TabPane>
                <TabPane tab="Control de Humedad" key="2">
                  <WallSystemsTab>
                    <WallSystemsTabH1>Resistencia al aire y el clima</WallSystemsTabH1>
                    <p>
                      El rendimiento térmico de un edificio se debe más a las propiedades aislantes
                      de los materiales utilizados. El hecho de que el edificio sea hermético al
                      aire y a la intemperie puede tener un efecto considerable en el rendimiento
                      térmico. Un edificio con corrientes de aire y fugas no controladas, disminuira
                      los esfuerzos de aislamiento, ya que el calor se filtrara por aperturas en la
                      fachada. Evitar que la humedad penetre en el muro es primordial, ya que ayuda
                      a reducir el riesgo de moho y los peligros para la salud que lo acompañan. La
                      familia de StoGuard® de barreras de aire y humedad de aplicación líquida ayuda
                      a sellar la envoltura del edificio y es un componente clave de todos nuestros
                      sistemas para muros de alto rendimiento.
                    </p>
                    <ul>
                      <li>
                        ASTM E 2357 fuga de aire del ensamblaje de la pared menos de 0.04 cfm/ft2 a
                        0.3" H2O (0.2 L/s-m2 a 75Pa)
                      </li>
                      <li>
                        Ahorro potencial del 40% en costos de calefacción y refrigeración según un
                        estudio del Departamento de Energía.
                      </li>
                      <li>Bajas emisiones de CO2.</li>
                    </ul>
                    <WallSystemsTabNorma>
                      *Libro de datos de energía de edificios del US DOE 2008, sección 1.1.1 2008
                    </WallSystemsTabNorma>
                  </WallSystemsTab>
                </TabPane>
                <TabPane tab="Protección al Fuego" key="3">
                  <WallSystemsTab>
                    <WallSystemsTabH1>
                      Cumplimiento de la seguridad contra incendios
                    </WallSystemsTabH1>
                    <p>
                      Los códigos de construcción se establecen para proporcionar seguridad vital a
                      los ocupantes de los edificios, y la protección contra incendios es un aspecto
                      importante de los códigos. Los edificios deben tener muros sometidos a pruebas
                      de incendio para cumplir todos los requisitos estrictos del Código Nacional de
                      Construcción. Sto ha hecho grandes inversiones en ensayos de incendio a gran
                      escala. Este proceso asegura que todos nuestros sistemas de paredes de alta
                      calidad han sido sometidos a extensas pruebas y evaluaciones y se desempeñarán
                      bien en una situación de incendio.
                    </p>
                    <ul>
                      <li>
                        Los informes de evaluación de la ICC están disponibles que atestiguan las
                        rigurosas pruebas de fuego que Sto ha realizado en sus sistemas de paredes.
                      </li>
                      <li>
                        Los productos de Sto son Clase A materiales probados según ASTM E 84
                        Propagación de la llama y humo desarrollado pruebas de fuego
                      </li>
                      <li>
                        Los montajes de los muros de Sto. cumplen con los requisitos de: <br /> -
                        Pruebas de fuego NFPA 285 diseñadas para evaluar el comportamiento ante el
                        fuego de los ensamblajes de muros con aislamiento combustible u otros
                        componentes combustibles (barrera de aire y humedad) para su uso en la
                        construcción de tipo no combustible <br /> - La prueba de fuego NFPA 268
                        diseñada para evaluar el potencial de un incendio en un edificio para
                        encender un edificio adyacente <br /> - La prueba de fuego ASTM E 119
                        diseñada para establecer la clasificación de resistencia al fuego por hora
                        del ensamblaje del muro.
                      </li>
                    </ul>
                  </WallSystemsTab>
                </TabPane>
                <TabPane tab="Estética" key="4">
                  <WallSystemsTab>
                    <WallSystemsTabH1>Estética Versatíl</WallSystemsTabH1>
                    <p>
                      El diseño de fachadas es el arte de dar a los edificios su propia apariencia y
                      carácter distintivo a través del uso de la forma, el color y la textura. El
                      atractivo y la calidad física del exterior de un edificio son una medida de la
                      realización arquitectónica y la artesanía ; son cruciales para el valor de la
                      propiedad. Los sistemas de muros de Sto unifican y satisfacen las exigencias
                      técnicas y estéticas de la envoltura del edificio gracias a una maravillosa
                      gama de estética de superficie única.
                    </p>
                    <ul>
                      <li>
                        Amplia gama de acabados de paredes decorativas y protectoras 100% acrílicas
                        que pueden ser producidas en colores virtualmente ilimitados.
                      </li>
                      <li>Sto tiene más de 4 millones de formulaciones de color.</li>
                      <li>
                        Acabados especiales de primera calidad incluyen opciones versatiles como el
                        aspecto de ladrillo, granito, piedra caliza, estuco e incluso metal.
                      </li>
                      <li>
                        Gama completa de revestimientos de alto rendimiento que proporcionan una
                        resistencia superior a la decoloración y resistencia a los rayos UV,
                        excelente resistencia a la intemperie y protección contra las algas, el moho
                        y el moho.
                      </li>
                      <li>
                        Exclusivo acabado patentado Stolit® Lotusan® con tecnología Lotus-Effect®
                        que puede ayudar a mantener las fachadas de los edificios limpias y secas a
                        largo plazo.
                      </li>
                      <li>
                        El Estudio de Sto, nuestro estudio de diseño integrado proporciona servicios
                        de consulta de color y textura, así como representaciones de color
                        arquitectónico.
                      </li>
                    </ul>
                  </WallSystemsTab>
                </TabPane>
                <TabPane tab="Sostentibilidad" key="5">
                  <WallSystemsTab>
                    <WallSystemsTabH1>Diseño sostenible</WallSystemsTabH1>
                    <p>
                      La acción sostenible y responsable es parte de nuestra filosofía corporativa.
                      Por eso el aislamiento térmico está particularmente cerca de nuestros
                      corazones. Nuestros sistemas para muros de alto rendimiento proporcionan una
                      resistencia superior al aire y a la intemperie, un excelente rendimiento
                      térmico duradero, ayudando así a reducir los costos de energía y a disminuir
                      la huella de carbono de los edificios.
                    </p>
                    <ul>
                      <li>
                        Los sistemas de aislamiento térmico para murosexteriores de Sto superan a
                        otros revestimientos - como el ladrillo o el estuco - en términos de
                        producción energética, energía de calefacción/refrigeración, residuos
                        sólidos y emisiones de gases de efecto invernadero durante un período
                        completo de 50 años de ciclo de vida.*
                      </li>
                      <li>
                        El alto valor R de nuestros sistemas de paredes aisladas minimiza los costos
                        de calefacción/refrigeración y reduce las emisiones de gases de efecto
                        invernadero, haciendo así que nuestros sistemas sean verdaderamente
                        sostenibles.
                      </li>
                      <li>
                        Algunos de los componentes de nuestro sistema son reciclables y contienen
                        contenido reciclado que puede ayudar con los puntos LEED.
                      </li>
                      <li>
                        Otras credenciales sostenibles incluyen: Cumplimiento de los requisitos de
                        COV de la EPA y SCAQMD, Sistema de gestión de calidad certificado por la ISO
                        9001:2008, Sistema de gestión medioambiental certificado por la ISO
                        14001:2004
                      </li>
                    </ul>
                    <WallSystemsTabNorma>
                      *Inventario del ciclo de vida realizado por Franklin Associates/ERG en abril
                      de 2009
                    </WallSystemsTabNorma>
                  </WallSystemsTab>
                </TabPane>
              </Tabs>
            </WallSystemsTabs>
            <NewConstructionTitleLarge>
              <NewConstructionTitleSeparator />
              <NewConstructionTitleText>
                Sistemas de Aislamiento Térmico Destacados
              </NewConstructionTitleText>
              <NewConstructionTitleSeparator />
            </NewConstructionTitleLarge>
            <Fade bottom>
              <WallSystemsFeatured>
                <WallSystemsFeaturedTitle>
                  <WallSystemsFeaturedTitleP>StoTherm ci®</WallSystemsFeaturedTitleP>
                  <WallSystemsFeaturedTitleSpan>
                    Sistemas de Aislamiento Continuo
                  </WallSystemsFeaturedTitleSpan>
                </WallSystemsFeaturedTitle>
                <WallSystemsFeaturedTitleImage>
                  <GatsbyImage
                    image={data.stoWallSystem.childImageSharp.gatsbyImageData}
                    alt="logo-stoWallsSystem"
                  />
                </WallSystemsFeaturedTitleImage>
              </WallSystemsFeatured>
            </Fade>
          </NewConstructionWallSystems>
        </DContainer>
        <AirBarries ref={airRef}>
          <Fade right>
            <DContainer>
              <AirBarriesIntro>
                <div>
                  <AirBarriesIntroH1>Barreras de Aire y Humedad</AirBarriesIntroH1>
                  <AirBarriesIntroP>
                    <strong>
                      <span>
                        Diseñado para proporcionar una protección superior contra la intrusión de
                        humedad y las fugas de aire no controladas - y ofrecer un importante ahorro
                        de energía en climas cálidos o fríos.
                      </span>
                    </strong>
                  </AirBarriesIntroP>
                  <AirBarriesIntroP>
                    Las Barreras de Aire y Humedad funcionan construyendo una envoltura hermética
                    continua del edificio. Son necesarias para los muros de los edificios,
                    independientemente de la región o el clima. Cuando se aplican correctamente,
                    estos componentes funcionan juntos como una barrera de aire y una barrera de
                    humedad sin fisuras en la instalación del muro y pueden reducir el riesgo de
                    problemas de humedad, tales como: descomposición, moho, corrosión, pérdida del
                    valor de aislamiento y la calidad del aire interior. <br />
                    <br /> La familia de productos StoGuard® de Barrera de Aire y Humedad están
                    diseñados para satisfacer una amplia variedad de requisitos de construcción.
                  </AirBarriesIntroP>
                </div>
                <div>
                  <GatsbyImage
                    image={data.airBarries.childImageSharp.gatsbyImageData}
                    alt="logo-airBarries"
                  />
                </div>
              </AirBarriesIntro>
            </DContainer>
          </Fade>
        </AirBarries>
        <Coatings ref={coatingsRef}>
          <Fade left>
            <DContainer>
              <CoatingsIntro>
                <div>
                  <GatsbyImage
                    image={data.coatings.childImageSharp.gatsbyImageData}
                    alt="logo-coatings"
                  />
                </div>
                <div>
                  <CoatingsIntroH1>Pinturas</CoatingsIntroH1>
                  <CoatingsIntroP>
                    <strong>
                      <span>
                        Sto ofrece una línea completa de recubrimientos de alto rendimiento de
                        primera calidad, incluyendo imprimantes, recubrimientos acrílicos y
                        elastómeros que crean un atractivo visual dramático y salvaguardan el valor
                        de su edificio.
                      </span>
                    </strong>
                  </CoatingsIntroP>
                  <CoatingsIntroP>
                    Los recubrimientos arquitectónicos exteriores de Sto son capaces de repeler la
                    lluvia impulsada por el viento, pero aún así permiten que un edificio respire.
                    Son resistentes a la decoloración y a la acumulación de suciedad, moho y hongos
                    para una belleza duradera. <br /> <br />
                    Una variedad de opciones de color y textura dentro de los productos de Sto,
                    StoColor® Lotusan®, StoLastic®, y otros recubrimientos de Sto le dan una gama
                    completa de opciones de diseño.
                  </CoatingsIntroP>
                </div>
              </CoatingsIntro>
            </DContainer>
          </Fade>
        </Coatings>
        <Coatings ref={systemsRef}>
          <Fade>
            <DContainer>
              <CoatingsIntro>
                <div>
                  <GatsbyImage
                    image={data.specialSystem.childImageSharp.gatsbyImageData}
                    alt="logo-specialSystem"
                  />
                </div>
                <div>
                  <CoatingsIntroH1>Sistemas Especiales</CoatingsIntroH1>
                  <CoatingsIntroP>
                    <strong>
                      <span>
                        Sto ofrece una variedad de sistemas especializados para sus proyectos
                        arquitectónicos.
                      </span>
                    </strong>
                  </CoatingsIntroP>
                  <CoatingsIntroP>
                    <strong>Sistemas de Acabado de Aplicación Directa para Cielo Raso:</strong>
                    <br /> El sistema StoQuik® Gold para sofitos es un sistema de acabado exterior
                    de aplicación directa (DEFS) sobre revestimientos de yeso o paneles de cemento
                    para su uso en aplicaciones de sofitos o techos exteriores no aislados y
                    protegidos contra las inclemencias del tiempo.
                  </CoatingsIntroP>
                </div>
              </CoatingsIntro>
            </DContainer>
          </Fade>
        </Coatings>
        <AirBarries ref={finishesRef}>
          <Fade left>
            <DContainer>
              <AirBarriesIntro>
                <div>
                  <AirBarriesIntroH1>Acabados Sto</AirBarriesIntroH1>
                  <AirBarriesIntroP>
                    <strong>
                      <span>
                        Sto ha ido más allá del aspecto de estuco básico para ofrecer una amplia
                        gama de oportunidades para la creatividad.
                      </span>
                    </strong>
                  </AirBarriesIntroP>
                  <AirBarriesIntroP>
                    Hoy en día, usted puede replicar casi todas las texturas - incluyendo ladrillo,
                    granito, piedra caliza y metal - con los acabados especiales de Sto. Para la
                    personalización que desea, junto con el ahorro de costos y el rendimiento que
                    necesita, elija Acabados Especiales de Sto. Los Acabados Estándar de Sto tienen
                    agregado de mármol blanco puro, 100% polímeros acrílicos, y una variedad de
                    texturas y opciones de color integral para mejorar el diseño y armonizar con el
                    medio ambiente circundante.
                  </AirBarriesIntroP>
                </div>
                <div>
                  <GatsbyImage
                    image={data.finishes.childImageSharp.gatsbyImageData}
                    alt="logo-finishes"
                  />
                </div>
              </AirBarriesIntro>
            </DContainer>
          </Fade>
        </AirBarries>
      </article>
    </Layout>
  );
});

export default NewConstruction;
